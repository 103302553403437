export const TYPES = {
    // Shorten
    SHORTEN_REQUEST: "SHORTEN_REQUEST",
    SHORTEN_REQUEST_SUCCESS: "SHORTEN_REQUEST_SUCCESS",

    FAILTURE: "FAILTURE",

    // Messagebox
    SET_MESSAGEBOX_SUCCESS: "SET_MESSAGEBOX_SUCCESS",
    SET_MESSAGEBOX_ERROR: "SET_MESSAGEBOX_ERROR",
    SET_MESSAGEBOX_DEFAULT: "SET_MESSAGEBOX_DEFAULT",

    // File
    GET_FILE_REQUEST: "GET_FILE_REQUEST",
    GET_FILE_SUCCESS: "GET_FILE_SUCCESS",
};
